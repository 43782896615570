import React, { useEffect } from "react";
import { Datepicker } from "@amzn/alchemy-components-react";
import { DEFAULT_LANG } from "src/common/constants";
import { convertDateToLocaleFormat } from "src/common/util";

// See following link for full list of props that are allowed:
// https://alchemy.aft.amazon.dev/components/datepicker
interface DisabledDates {
    to?: Date
}

/**
 * Input parameters for creating a LocalizedDatepicker component.
 */
interface LocalizedDatepickerProps {
    id: string,
    label?: string,
    placeholder?: Date,
    selectedDate?: Date,
    onInput?: (...args: any[]) => any,
    status?: string,
    disabledDates?: DisabledDates,
    helpText?: string,
}

/**
 * Component for selecting dates that is localized.
 */
export const LocalizedDatepicker = (datepickerProps: LocalizedDatepickerProps) => {
    const locale = document.documentElement.lang;   // e.g. en-US
    const language = locale.split('-')[0];          // e.g. en

    // This gets triggered whenever the locale is changed
    useEffect(() => {
        if (datepickerProps.selectedDate) {
            const el = document.getElementById(datepickerProps.id)!;

            // This is a hacky solution that should be revised once the Alchemy team provides a better way to update
            // format of selectedDate upon changing locale
            // @ts-ignore
            el.selectedDate = new Date(datepickerProps.selectedDate);
        }
    }, [locale]);

    const getLocaleDateFormat = (locale: string) : string => {
        const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());

        return formatObj
            .map(obj => {
                switch (obj.type) {
                    case "day":
                        return "dd";
                    case "month":
                        return "MM";
                    case "year":
                        return "yyyy";
                    default:
                        return obj.value;
                }
            })
            .join("");
    }

    return (
        <Datepicker
            id={datepickerProps.id}
            label={datepickerProps.label}
            placeholder={convertDateToLocaleFormat(locale, datepickerProps.placeholder)}
            onInput={datepickerProps.onInput}
            status={datepickerProps.status}
            language={language}
            format={getLocaleDateFormat(locale)}
            disabledDates={datepickerProps.disabledDates}
            typeable={locale === DEFAULT_LANG} // There is a bug where date can't be entered via typing when date is not
                                               // in the format of MM/dd/yyyy, so disable typing for now
            helpText={datepickerProps.helpText}
        />
    )
}