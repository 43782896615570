import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { Accordion, Autocomplete, Button, Dropdown, Input, Modal } from "@amzn/alchemy-components-react";
import {
    AlertType,
    BackgroundCheckVendorName,
    NationalIdCountry,
    NationalIdType,
    PageType,
} from "src/common/enums";
import { BreadCrumbs } from 'src/components/breadcrumb';
import { FallbackSpinner } from "src/components/fallback-spinner";
import { AlertBar } from "src/components/alert-bar";
import { PageProps } from "src/pages/page-interface";
import { LocalizedDatepicker } from "src/components/localized-datepicker";
import { logger } from "src/logger";
import { convertDateToPreStartFormat, getTranslatedErrorMsg, isEmailValid } from "src/common/util";
import { ONBOARD_USER_MUTATION } from "src/common/gql-operations";
import { OnboardUserData, OnboardUserInput, OnboardUserVariables } from "src/models/onboard-user";
import * as KatalMetrics from "@amzn/katal-metrics";
import initialMetricsPublisher from "src/metrics";
import { ValidationError } from "src/models/validation-error";
import { useAuth } from "src/components/auth/auth-provider";
import {
    ENTERPRISE_SCOPE,
    GENDER_OPTIONS,
    ONBOARDABLE_USER_TYPES,
    NATIONAL_ID_COUNTRY_OPTIONS,
    NATIONAL_ID_TYPE_OPTIONS,
    AFTX_BASE_ROLES
} from "src/common/constants";
import { TenantID } from "src/common/enums";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('page', 'single-onboard'),
]

// @ts-ignore
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const graphqlClientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('graphql-client', additionalMetricsContext);

/**
 * Principal onboard function renders the page and handles functions related to onboarding one or more principals.
 */
export const SingleOnboard = (props: PageProps) => {

    const auth = useAuth();
    const {t} = useTranslation();

    // https://quip-amazon.com/maPiArPvveoi/Pre-Start-API-v2-Spec#temp:C:DXB6ceaebb615bf438593c544cc9
    const MAX_NAME_LENGTH = 30;

    const ONE_DAY = 24 * 60 * 60 * 1000; // milliseconds
    const ONE_DAY_AGO = new Date(Date.now() - ONE_DAY);

    const DEFAULT_HIRE_PERIOD = 1000 * 86400 * 180; // 180 days
    const DEFAULT_OPTION = {label: t('choose-an-option'), value: ''};

    const userTypeOptions = [
        DEFAULT_OPTION,
    ].concat(ONBOARDABLE_USER_TYPES
        .map(userType => ({label: userType, value: userType})));

    const aftxRoleOptions = [
        DEFAULT_OPTION,
    ].concat(AFTX_BASE_ROLES
        .map(aftxRole => ({label: aftxRole, value: aftxRole})));

    const nationalIdCountryOptions = [
        DEFAULT_OPTION,
    ].concat(NATIONAL_ID_COUNTRY_OPTIONS);

    const nationalIdTypeOptions = [
        DEFAULT_OPTION,
    ].concat(NATIONAL_ID_TYPE_OPTIONS);

    const vendorNameOptions = [
        DEFAULT_OPTION,
    ].concat(Object.values(BackgroundCheckVendorName)
        .map(vendor => ({label: vendor, value:vendor})));

    let genderOptions = [
        DEFAULT_OPTION,
    ].concat(GENDER_OPTIONS.map((option) => ({label: t(option.label), value: option.value})));

    const [formKey, setFormKey] = useState<string>(Date.now().toString());

    const [getPrincipalScopesLoading, setGetPrincipalScopesLoading] = useState<boolean>(true);
    const [scopeOptions, setScopeOptions] = useState<any[]>([DEFAULT_OPTION]);

    const [scope, setScope] = useState<string>('');
    const [scopeStatus, setScopeStatus] = useState<string>('');
    const [userType, setUserType] = useState<string>('');
    const [userTypeStatus, setUserTypeStatus] = useState<string>('');
    const [aftxRole, setAftxRole] = useState<string>('');
    const [aftxRoleStatus, setAftxRoleStatus] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<Date>();
    const [dateOfBirthStatus, setDateOfBirthStatus] = useState<string>('');
    const [gender, setGender] = useState<string>(genderOptions[3].value);
    const [genderStatus, setGenderStatus] = useState<string>('');
    const [startDate, setStartDate] = useState<Date>();
    const [startDateStatus, setStartDateStatus] = useState<string>('');
    const [endDate, setEndDate] = useState<Date>();
    const [endDateStatus, setEndDateStatus] = useState<string>('');
    const [legalFirstName, setLegalFirstName] = useState<string>('');
    const [legalFirstNameStatus, setLegalFirstNameStatus] = useState<string>('');
    const [legalMiddleName, setLegalMiddleName] = useState<string>('');
    const [legalLastName, setLegalLastName] = useState<string>('');
    const [legalLastNameStatus, setLegalLastNameStatus] = useState<string>('');
    const [preferredFirstName, setPreferredFirstName] = useState<string>('');
    const [preferredFirstNameStatus, setPreferredFirstNameStatus] = useState<string>('');
    const [preferredMiddleName, setPreferredMiddleName] = useState<string>('');
    const [preferredLastName, setPreferredLastName] = useState<string>('');
    const [preferredLastNameStatus, setPreferredLastNameStatus] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailStatus, setEmailStatus] = useState<string>('');
    const [nationalIdCountry, setNationalIdCountry] = useState<string>('');
    const [nationalIdCountryStatus, setNationalIdCountryStatus] = useState<string>('');
    const [nationalIdType, setNationalIdType] = useState<string>('');
    const [nationalIdTypeStatus, setNationalIdTypeStatus] = useState<string>('');
    const [nationalId, setNationalId] = useState<string>('');
    const [nationalIdStatus, setNationalIdStatus] = useState<string>('');
    const [nationalIdHelpText, setNationalIdHelpText] = useState<string>(t('national-id-tail-help-text-generic'));
    const [backgroundCheckVendorName, setBackgroundCheckVendorName] = useState<string>('');
    const [backgroundCheckVendorNameStatus, setBackgroundCheckVendorNameStatus] = useState<string>('');
    const [backgroundCheckReferenceNumber, setBackgroundCheckReferenceNumber] = useState<string>('');
    const [backgroundCheckReferenceNumberStatus, setBackgroundCheckReferenceNumberStatus] = useState<string>('');
    const [personalInfoExpanded, setPersonalInfoExpanded] = useState<boolean>(true);
    const [identificationExpanded, setIdentificationExpanded] = useState<boolean>(true);
    const [backgroundCheckExpanded, setBackgroundCheckExpanded] = useState<boolean>(true);

    const [alertBarType, setAlertBarType] = useState<AlertType>();
    const [alertBarHeader, setAlertBarHeader] = useState<string>();
    const [alertBarMsg, setAlertBarMsg] = useState<string>();

    // Holds validation errors that aren't caught by front-end
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>();

    const [onboardUser, {
        loading: onboardUserLoading,
    }] = useMutation<OnboardUserData, OnboardUserVariables>(ONBOARD_USER_MUTATION, {
        onCompleted: data => {
            logger.info(`Retrieved data from onboardUser with workflowId ${data.onboardUser.workflowId}`);

            if (!data.onboardUser.workflowId) {
                let userValidationErrors = data.onboardUser.validationResult.errors;
                let commonValidationErrors: ValidationError[] = [];
                if (data.onboardUser.commonValidationErrors) {
                    commonValidationErrors = data.onboardUser.commonValidationErrors;
                }
                let validationErrors = commonValidationErrors.concat(userValidationErrors)
                logger.info("Single User Onboard data failed validation with errors: " + validationErrors);
                graphqlClientMetricsPublisher.publishCounterMonitor('single-onboard-user.VALIDATION_ERROR', 1);
                setValidationErrors(validationErrors);
                showErrorAlert(t('submission-failed'), t('please-correct-the-validation-errors-and-resubmit'));
            } else {
                logger.info("Single User Onboard workflow execution started: " + data.onboardUser.workflowId);
                graphqlClientMetricsPublisher.publishCounterMonitor('single-onboard-user.SUCCESS', 1);
                showSuccessAlert(t('success'), t('single-user-onboard-data-submitted'));
                resetForm();
            }
        },
        onError: error => {
            logger.error("Call to onboardUser failed!", error);
            graphqlClientMetricsPublisher.publishCounterMonitor('single-onboard-user.ERROR', 1);
            showErrorAlert(t('submission-failed'), error.message);
        }
    });

    const callOnboardUser = () => {
        setAlertBarMsg(undefined); // reset alert bar message
        setValidationErrors(undefined); // reset errors

        if (!isFormValid()) {
            return;
        }

        const onboardUserInput: OnboardUserInput = {
            tenantId: TenantID.AFTX,
            user: {
                scope: scope,
                userType: userType,
                aftx: {
                    roles: [
                        aftxRole
                    ]
                },
                dateOfBirth: convertDateToPreStartFormat(dateOfBirth!),
                gender: gender,
                email: email,
                startDate: convertDateToPreStartFormat(startDate!),
                endDate: convertDateToPreStartFormat(endDate!),
                legalName: {
                    firstName: legalFirstName,
                    middleName: legalMiddleName,
                    lastName: legalLastName
                },
                preferredName: {
                    firstName: preferredFirstName,
                    middleName: preferredMiddleName,
                    lastName: preferredLastName
                },
                // TODO: these parameters are still pending further discussion with Generic Hire, to determine what the appropriate values are for the various regions
                nationalId: nationalId,
                nationalIdCountry: nationalIdCountry,
                nationalIdType: nationalIdType,
                backgroundCheckVendorName: backgroundCheckVendorName,
                backgroundCheckReferenceNumber: backgroundCheckReferenceNumber,
                // We don't have buy-in from business to allow background check exceptions, so leave this empty for now
                // TODO: Set this value once business approves (https://issues.amazon.com/issues/AFTI-1179)
                backgroundCheckExceptionId: ''
            }
        };

        logger.info(`Calling onboardUser for scope: ${onboardUserInput.user.scope} and usertype: ${onboardUserInput.user.userType}`);
        graphqlClientMetricsPublisher.publishCounterMonitor('single-onboard-user.INVOCATION', 1);
        onboardUser({
            variables: {
                onboardUserInput: onboardUserInput
            }
        });
    }

    const isFormValid = () => {
        let isFormValid = true;

        if (!scope) {
            setScopeStatus(t("warehouse-invalid-status"));
            isFormValid = false;
        }

        if (!userType) {
            setUserTypeStatus(t("user-type-invalid-status"));
            isFormValid = false;
        }

        if (!aftxRole) {
            setAftxRoleStatus(t("aftx-role-invalid-status"));
            isFormValid = false;
        }

        if (!startDate) {
            setStartDateStatus(t("start-date-invalid-status"));
            isFormValid = false;
        }

        if (!endDate) {
            setEndDateStatus(t("end-date-invalid-status"));
            isFormValid = false;
        }

        if (!isPersonalInfoValid()) {
            setPersonalInfoExpanded(true);
            isFormValid = false;
        }

        if (!isIdentificationValid()) {
            setIdentificationExpanded(true);
            isFormValid = false;
        }

        if (!isBackgroundCheckValid()) {
            setBackgroundCheckExpanded(true);
            isFormValid = false;
        }

        return isFormValid;
    }

    const isPersonalInfoValid = () => {
        let isValid = true;

        if (!legalFirstName) {
            setLegalFirstNameStatus(t("legal-first-name-invalid-status"));
            isValid = false;
        }

        if (!legalLastName) {
            setLegalLastNameStatus(t("legal-last-name-invalid-status"));
            isValid = false;
        }

        if (!preferredFirstName && preferredLastName) {
            setPreferredFirstNameStatus(t("preferred-first-name-invalid-status"));
            isValid = false;
        }

        if (!preferredLastName && preferredFirstName) {
            setPreferredLastNameStatus(t("preferred-last-name-invalid-status"));
            isValid = false;
        }

        if (!isEmailValid(email)) {
            setEmailStatus(t("email-invalid-status"));
            isValid = false;
        }

        if (!gender) {
            setGenderStatus(t("gender-invalid-status"));
            isValid = false;
        }

        if (!dateOfBirth) {
            setDateOfBirthStatus(t("date-of-birth-invalid-status"));
            isValid = false;
        }

        return isValid;
    }

    const isIdentificationValid = () => {
        let isValid = true;

        if (!nationalIdCountry) {
            setNationalIdCountryStatus(t("national-id-country-invalid-status"));
            isValid = false;
        }

        // Check if nationalIdType is invalid only if nationalIdCountry is set
        if (!nationalIdType && nationalIdCountry) {
            setNationalIdTypeStatus(t("national-id-type-invalid-status"));
            isValid = false;
        }

        // Check if nationalId is invalid only if both nationalIdType and nationalIdCountry are set
        if (!isNationalIdValid(nationalId) && nationalIdType && nationalIdCountry) {
            setNationalIdStatus(getNationalIdInvalidStatusMsg(nationalIdType, nationalIdCountry));
            isValid = false;
        }

        return isValid;
    }

    const isBackgroundCheckValid = () => {
        let isValid = true;

        if (!backgroundCheckVendorName) {
            setBackgroundCheckVendorNameStatus(t("vendor-name-invalid-status-2"));
            isValid = false;
        }

        if (!backgroundCheckReferenceNumber) {
            setBackgroundCheckReferenceNumberStatus(t("vendor-ref-number-invalid-status"));
            isValid = false;
        }

        return isValid;
    }

    /**
     * Checks if the National Id is valid.
     *
     * @param nationalId The value to validate.
     * @return true if valid; otherwise, false.
     */
    const isNationalIdValid = (nationalId: string): boolean => {
        // Field is required so if a value is not present then it's invalid
        if (!nationalId) {
            return false;
        }

        // TODO: Add validation logic for other National Id types
        if (nationalIdType === NationalIdType.NATIONAL_ID_TAIL && nationalIdCountry === NationalIdCountry.USA) {
            return new RegExp(`^\\d{4}$`).test(nationalId);
        } else if (nationalIdType === NationalIdType.NATIONAL_ID_TAIL) {
            return new RegExp(`^[a-zA-Z0-9]{4}$`).test(nationalId);
        } else {
            return false;
        }
    }

    /**
     * Gets the status message to display based on the National Id Type when the National Id is invalid.
     *
     * @param nationalIdType The type of National Id.
     * @param nationalIdCountry The National Id country.
     * @return the status message.
     */
    const getNationalIdInvalidStatusMsg = (nationalIdType: string, nationalIdCountry: string): string => {
        // TODO: Add messages for other National Id types
        if (nationalIdType === NationalIdType.NATIONAL_ID_TAIL && nationalIdCountry === NationalIdCountry.USA) {
            return t("national-id-tail-invalid-digits-status-usa");
        } else if (nationalIdType === NationalIdType.NATIONAL_ID_TAIL) {
            return t("national-id-tail-invalid-characters-status-generic");
        } else {
            return t("national-id-invalid-status");
        }
    }

    /* istanbul ignore next */
    const setTestDatePickerValues = (e: FormEvent) => {
        const startDate = new Date();
        // @ts-ignore
        document.getElementById('start-date')!.selectedDate = startDate;
        setStartDate(startDate);

        const endDate = new Date(Date.now() + DEFAULT_HIRE_PERIOD);
        // @ts-ignore
        document.getElementById('end-date')!.selectedDate = endDate;
        setEndDate(endDate);

        const dateOfBirth = new Date(1900, 0, 1);
        // @ts-ignore
        document.getElementById('date-of-birth')!.selectedDate = dateOfBirth;
        setDateOfBirth(dateOfBirth);

        e.preventDefault();
    }

    const onInputPreferredFirstName = (preferredFirstName: string) => {
        setPreferredFirstName(preferredFirstName);

        if (preferredFirstName) {
            setPreferredFirstNameStatus('');
        } else if (!preferredFirstName && !preferredLastName) {
            setPreferredFirstNameStatus('');
            setPreferredLastNameStatus('');
        }
    }

    const onInputPreferredLastName = (preferredLastName: string) => {
        setPreferredLastName(preferredLastName);

        if (preferredLastName) {
            setPreferredLastNameStatus('');
        } else if (!preferredLastName && !preferredFirstName) {
            setPreferredLastNameStatus('');
            setPreferredFirstNameStatus('');
        }
    }

    const onChangeNationalIdCountry = (nationalIdCountry: string) => {
        setNationalIdCountry(nationalIdCountry);

        // Clear dropdowns that depend upon nationalIdCountry
        setNationalIdType(NationalIdType.NATIONAL_ID_TAIL);

        // Update the labels
        switch (nationalIdCountry) {
            case NationalIdCountry.USA:
                setNationalIdHelpText(t('national-id-tail-help-text-usa'));
                break;
            case NationalIdCountry.GBR:
                setNationalIdHelpText(t('national-id-tail-help-text-gbr'));
                break;
            default:
                setNationalIdHelpText(t('national-id-tail-help-text-generic'));
                break;
        }
        setNationalIdStatus('');
        setNationalId('');
    }

    const onChangeNationalIdType = (nationalIdType: string) => {
        setNationalIdType(nationalIdType);

        // Clear elements that depend upon nationalIdType
        setNationalId('');
        setNationalIdStatus('');
    }

    const onInputEmail = (email: string) => {
        setEmail(email);
        setEmailStatus('');
    }

    /**
     * Shows a success via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showSuccessAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.success);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Shows an error via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showErrorAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.error);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Resets the AlertBar since it's re-usable.
     */
    const resetAlertBar = () => {
        setAlertBarType(undefined);
        setAlertBarHeader(undefined);
        setAlertBarMsg(undefined);
    }

    /**
     * Resets the Single Onboard form.
     */
    const resetForm = () => {
        setScope('');
        setScopeStatus('');
        setUserType('');
        setUserTypeStatus('');
        setAftxRoleStatus('');
        setStartDate(undefined);
        setStartDateStatus('');
        setEndDate(undefined);
        setEndDateStatus('');
        setLegalFirstName('');
        setLegalFirstNameStatus('');
        setLegalMiddleName('');
        setLegalLastName('');
        setLegalLastNameStatus('');
        setPreferredFirstName('');
        setPreferredLastNameStatus('');
        setPreferredMiddleName('');
        setPreferredLastName('');
        setPreferredLastNameStatus('');
        setEmail('');
        setEmailStatus('');
        setGender(genderOptions[3].value);
        setGenderStatus('');
        setDateOfBirth(undefined);
        setDateOfBirthStatus('');
        setNationalIdCountry('');
        setNationalIdCountryStatus('');
        setNationalIdType('');
        setNationalIdTypeStatus('');
        setNationalId('');
        setNationalIdStatus('');
        setNationalIdHelpText(t('national-id-tail-help-text-generic'));
        setBackgroundCheckVendorName('');
        setBackgroundCheckVendorNameStatus('');
        setBackgroundCheckReferenceNumber('');
        setBackgroundCheckReferenceNumberStatus('');

        // Force form to re-render
        // Note: This is necessary for the Datepicker components to be reset
        setFormKey(Date.now().toString());
    }

    /**
     * Populates the dropdown with the scopes to which the current user has access.
     */
    const populateScopes = async() => {
        const scopes = await auth.getScopes();

        if (scopes && scopes.length > 0) {
            scopes
                // Enterprise should not be an option for onboarding of anyone
                .filter(scope => scope !== ENTERPRISE_SCOPE)
                .forEach(scope => scopeOptions.push({
                label: scope,
                value: scope
            }));
        } else {
            showErrorAlert(t('error'), t('failed-to-get-sites-user-can-access'));
        }

        setScopeOptions(scopeOptions);
        setGetPrincipalScopesLoading(false);
    }

    useEffect(() => {
        props.setActivePage(PageType.ONBOARD);
        populateScopes();
    }, []);

    return (
        <div id="single-onboard-page" className="container-fluid">
            <div className="row">
                <BreadCrumbs breadcrumbItems={[
                    {tag: t('onboard'), path: '/onboard'},
                    {tag: t('single'), path: '/onboard/single'}
                ]}/>
            </div>
            <div className="row b-background mx-0 mb-0-5">
                <div className="col align-self-center title m-0 py-1">{t('single-user-onboard')}</div>
            </div>
            <div className="row d-flex py-2 mx-0 justify-content-center b-background">
                <form id="single-onboard-form" key={formKey}>
                    <div className="row">
                        <div className="col-sm-4">
                            <Dropdown
                                id="scope"
                                label={t('warehouse')}
                                options={scopeOptions}
                                value={scope}
                                onChange={e => setScope(e.target.value)}
                                status={scope ? '' : scopeStatus}
                            />
                        </div>
                        <div className="col-sm-4">
                            <Dropdown
                                id="user-type"
                                label={t('user-type')}
                                options={userTypeOptions}
                                value={userType}
                                onChange={e => setUserType(e.target.value)}
                                status={userType ? '' : userTypeStatus}
                            />
                        </div>
                        <div className="col-sm-4">
                            <Dropdown
                                id="aftx-role"
                                label={t('role')}
                                options={aftxRoleOptions}
                                onChange={e => setAftxRole(e.target.value)}
                                status={aftxRole ? '' : aftxRoleStatus}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <LocalizedDatepicker
                                id="start-date"
                                label={t('hire-start-date')}
                                placeholder={new Date()}
                                onInput={e => setStartDate(e.detail)}
                                status={startDate ? '' : startDateStatus}
                                selectedDate={startDate}
                                disabledDates={{
                                    to: ONE_DAY_AGO
                                }}
                                helpText={t('hire-start-date-help-text')}
                            />
                        </div>
                        <div className="col-sm-6">
                            <LocalizedDatepicker
                                id="end-date"
                                label={t('estimated-end-date')}
                                placeholder={new Date(Date.now() + DEFAULT_HIRE_PERIOD)}
                                onInput={e => setEndDate(e.detail)}
                                status={endDate ? '' : endDateStatus}
                                selectedDate={endDate}
                            />
                        </div>
                    </div>
                    <Accordion
                        id="personal-info"
                        header={t('personal-info')}
                        expanded={personalInfoExpanded}
                        onToggle={() => setPersonalInfoExpanded(!personalInfoExpanded)}
                        className="mt-2"
                    >
                        <div className="row">
                            <div className="col-sm-4">
                                <Input
                                    id="legal-first-name"
                                    label={t('legal-first-name')}
                                    name="legal-first-name"
                                    value={legalFirstName}
                                    onInput={e => setLegalFirstName(e.target.value)}
                                    status={legalFirstName ? '' : legalFirstNameStatus}
                                    maxlength={MAX_NAME_LENGTH}
                                />
                            </div>
                            <div className="col-sm-4">
                                <Input
                                    id="legal-middle-name"
                                    label={t('legal-middle-name-optional')}
                                    name="legal-middle-name"
                                    value={legalMiddleName}
                                    onInput={e => setLegalMiddleName(e.target.value)}
                                    maxlength={MAX_NAME_LENGTH}
                                />
                            </div>
                            <div className="col-sm-4">
                                <Input
                                    id="legal-last-name"
                                    label={t('legal-last-name')}
                                    name="legal-last-name"
                                    value={legalLastName}
                                    onInput={e => setLegalLastName(e.target.value)}
                                    status={legalLastName ? '' : legalLastNameStatus}
                                    maxlength={MAX_NAME_LENGTH}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <Input
                                    id="preferred-first-name"
                                    label={(!preferredFirstName && !preferredLastName) ? t('preferred-first-name-optional') : t('preferred-first-name')}
                                    name="preferred-first-name"
                                    value={preferredFirstName}
                                    onInput={e => onInputPreferredFirstName(e.target.value)}
                                    status={preferredFirstNameStatus}
                                    maxlength={MAX_NAME_LENGTH}
                                />
                            </div>
                            <div className="col-sm-4">
                                <Input
                                    id="preferred-middle-name"
                                    label={t('preferred-middle-name-optional')}
                                    name="preferred-middle-name"
                                    value={preferredMiddleName}
                                    onInput={e => setPreferredMiddleName(e.target.value)}
                                    maxlength={MAX_NAME_LENGTH}
                                />
                            </div>
                            <div className="col-sm-4">
                                <Input
                                    id="preferred-last-name"
                                    label={(!preferredFirstName && !preferredLastName) ? t('preferred-last-name-optional') : t('preferred-last-name')}
                                    name="preferred-last-name"
                                    value={preferredLastName}
                                    onInput={e => onInputPreferredLastName(e.target.value)}
                                    status={preferredLastNameStatus}
                                    maxlength={MAX_NAME_LENGTH}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Input
                                    id="email"
                                    label={t('email')}
                                    placeholder="example@email.com"
                                    name="email"
                                    type="email"
                                    value={email}
                                    onInput={e => onInputEmail(e.target.value)}
                                    status={emailStatus}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <Dropdown
                                    id="gender"
                                    label={t('gender')}
                                    options={genderOptions}
                                    value={gender}
                                    onChange={e => setGender(e.target.value)}
                                    status={gender ? '' : genderStatus}
                                />
                            </div>
                            <div className="col-sm-6">
                                <LocalizedDatepicker
                                    id="date-of-birth"
                                    label={t('date-of-birth')}
                                    placeholder={new Date(1970, 0, 1)}
                                    onInput={e => setDateOfBirth(e.detail)}
                                    status={dateOfBirth ? '' : dateOfBirthStatus}
                                    selectedDate={dateOfBirth}
                                />
                            </div>
                        </div>
                    </Accordion>
                    <Accordion
                        id="identification"
                        header={t('identification')}
                        expanded={identificationExpanded}
                        onToggle={() => setIdentificationExpanded(!identificationExpanded)}
                        className="mt-2"
                    >
                        <div className="row">
                            <div className="col-sm-6">
                                <Autocomplete
                                    id="national-id-country"
                                    label={t('national-id-country')}
                                    options={nationalIdCountryOptions}
                                    value={nationalIdCountry}
                                    onChange={e => onChangeNationalIdCountry(e.target.value)}
                                    trigger={1}
                                    status={nationalIdCountry ? '' : nationalIdCountryStatus}
                                />
                            </div>
                            <div className="hidden">
                                <Dropdown
                                    hidden={true}
                                    id="national-id-type"
                                    label={t('national-id-type')}
                                    options={nationalIdTypeOptions}
                                    value={nationalIdType}
                                    onChange={e => onChangeNationalIdType(e.target.value)}
                                    status={(nationalIdType || !nationalIdCountry) ? '' : nationalIdTypeStatus}
                                    disabled={!nationalIdCountry}
                                />
                            </div>
                            <div className="col-sm-6">
                                <Input
                                    id="national-id"
                                    label={t('national-id')}
                                    helpText={nationalIdHelpText}
                                    name="national-id"
                                    value={nationalId}
                                    onInput={e => setNationalId(e.target.value)}
                                    status={(isNationalIdValid(nationalId) || (!nationalIdCountry || !nationalIdType)) ? '' : nationalIdStatus}
                                    disabled={!nationalIdCountry || !nationalIdType}
                                />
                            </div>
                        </div>
                    </Accordion>
                    <Accordion
                        id="background-check"
                        header={t('background-check')}
                        expanded={backgroundCheckExpanded}
                        onToggle={() => setBackgroundCheckExpanded(!backgroundCheckExpanded)}
                        className="mt-2"
                    >
                        <div className="row">
                            <div className="col-sm-6">
                                <Dropdown
                                    id="background-check-vendor-name"
                                    label={t('vendor-name')}
                                    options={vendorNameOptions}
                                    value={backgroundCheckVendorName}
                                    onChange={e => setBackgroundCheckVendorName(e.target.value)}
                                    status={backgroundCheckVendorName ? '' : backgroundCheckVendorNameStatus}
                                />
                            </div>
                            <div className="col-sm-6">
                                <Input
                                    id="background-check-reference-number"
                                    label={t('vendor-ref-number')}
                                    name="background-check-reference-number"
                                    value={backgroundCheckReferenceNumber}
                                    onInput={e => setBackgroundCheckReferenceNumber(e.target.value)}
                                    status={backgroundCheckReferenceNumber ? '' : backgroundCheckReferenceNumberStatus}
                                />
                            </div>
                        </div>
                    </Accordion>
                </form>
            </div>
            <div className="mt-0 py-2 mx-0 mb-5 row d-flex justify-content-center b-background">
                <Button
                    id="onboard-user-submit"
                    label={t("submit")}
                    size="lg"
                    variant="action"
                    onClick={callOnboardUser}
                />
                <Button
                    id="set-test-datepicker-values"
                    onClick={setTestDatePickerValues}
                    hidden
                />
            </div>
            {validationErrors &&
                <Modal id="validation-errors-modal"
                       header={t('validation-errors')}
                       open={true}
                >
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <ul>
                                {validationErrors.map((error, i) => {
                                    return (
                                        <li key={i}>{getTranslatedErrorMsg(t, error)}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </Modal>
            }
            {(getPrincipalScopesLoading || onboardUserLoading) &&
                <FallbackSpinner/>
            }
            {alertBarMsg &&
                <AlertBar
                    id="single-onboard-alert-bar"
                    result={alertBarType!}
                    dismissible={true}
                    header={alertBarHeader!}
                    message={alertBarMsg!}
                    reset={resetAlertBar}
                />
            }
        </div>
    );
}

export default SingleOnboard;
