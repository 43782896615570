import Excel, { Workbook, Worksheet } from "exceljs";
import {
    DATA_SHEET_NAME,
    DATA_START_EXCEL_ROW,
    DATA_END_EXCEL_ROW,
    COLUMN_NAME_EXCEL_ROW,
    DO_NOT_DELETE_EXCEL_SHEET_PROTECTION_STRING,
    BULK_ONBOARD_IMPORT_VERSION,
    BLACK_COLOR_HEXADECIMAL,
    FILLED_IN_VIA_A_REFERENCE_AT_RUNTIME,
    GENDER_OPTIONS,
    TEN_YEARS_IN_MILLISECONDS,
    DATE_FORMAT,
    DOB_CUTOFF_IN_YEARS,
    NATIONAL_ID_COUNTRY_OPTIONS,
    NATIONAL_ID_TYPE_OPTIONS,
    EXCEL_DATE_FORMAT, ONBOARDABLE_USER_TYPES, AFTX_BASE_ROLES,
} from "src/common/constants";
// @ts-ignore
import { TFunction } from "react-i18next";
import { logger } from "src/logger";
import {BackgroundCheckVendorName, UserType} from "src/common/enums";
import {
    convertLocalTimeStampString,
    convertOptionLabelToValue,
    convertOptionValueToValue,
    convertToListValue
} from "src/common/excel-util";


/**
 * Definitions used to generate and import the input data fields for the bulk onboard Excel sheet.
 *
 * @var header The translated string at the top of the column.
 * @var columnLetter The column letter for this field examples are A, B, AE, etc
 * @var requiredLabelHeader The required label header in the example worksheet.
 * @var exampleDescription The translated explanation string in the example worksheet.
 * @var numFmt The cells format which is used for numbers, date, text, etc. not just numbers.
 * @var dataValidation The object used for data validation
 * @var additionalCellValueConversion This is a function used for additional cleaning of the parsed data during import.
 */
export interface DataInputField {
    header: string,
    columnLetter: string,
    requiredLabelHeader: string,
    exampleDescription?: string,
    numFmt?: string,
    dataValidation?: Excel.DataValidation,
    dataValidationList?: string[],
    additionalCellValueConversion?: (value: string) => string,
}

/**
 * ***Changes will very likely require versioning the Excel file to support bulk onboarding changes!
 * Be careful when making changes!!!!
 *
 * We do not support versioning the bulk onboard Excel file at this time!
 *
 * Note: The 'buot' prefix stands for Bulk User Onboard Template
 * Note: Replacing commas in lists below with ', ' in order for cell wrapping to occur on spaces
 **/
export const constructDataInputFields = (t: TFunction, scopeOptions: string[]): DataInputField[] => ([
    {
        header: t('warehouse') as string,
        columnLetter: "A",
        requiredLabelHeader: t('required') as string,
        exampleDescription: t('buot-field-must-contain-3pl-warehouse') as string,
        dataValidation: {
            type: "list",
            formulae: FILLED_IN_VIA_A_REFERENCE_AT_RUNTIME,
            allowBlank: true,
            showInputMessage: true,
            promptTitle: t('select-fulfillment-center'),
            prompt: t('select-fulfillment-center-description'),
            errorStyle: 'warning',
            showErrorMessage: true,
            errorTitle: t('select-valid-option'),
            error: t('invalid-fulfillment-center-entered'),
        },
        dataValidationList: scopeOptions,
        additionalCellValueConversion: (inputValue: string) => inputValue.toUpperCase()
    },
    {
        header: t('user-type') as string,
        columnLetter: "B",
        requiredLabelHeader: t('required') as string,
        exampleDescription: t('buot-field-must-contain-one-of-following-options', {
            options: ONBOARDABLE_USER_TYPES.toString().replace(/,/g, ', ')
        }) as string,
        dataValidation: {
            type: 'list',
            formulae: FILLED_IN_VIA_A_REFERENCE_AT_RUNTIME,
            allowBlank: true,
            showErrorMessage: true,
            errorTitle: t('select-valid-option'),
            error: t('excel-dropdown-entry-error')
        },
        dataValidationList: ONBOARDABLE_USER_TYPES,
        additionalCellValueConversion: (inputValue: string) => inputValue.toUpperCase(),
    },
    {
        header: t('role') as string,
        columnLetter: "C",
        requiredLabelHeader: t('required') as string,
        exampleDescription: t('buot-field-must-contain-one-of-following-options', {
            options: AFTX_BASE_ROLES.toString().replace(/,/g, ', ')
        }) as string,
        dataValidation: {
            type: "list",
            formulae: FILLED_IN_VIA_A_REFERENCE_AT_RUNTIME,
            allowBlank: true,
            showErrorMessage: true,
            errorTitle: t('select-valid-option'),
            error: t('excel-dropdown-entry-error'),
        },
        dataValidationList: AFTX_BASE_ROLES,
    },
    {
        header: t('hire-start-date') as string,
        columnLetter: "D",
        requiredLabelHeader: t('required') as string,
        exampleDescription: t('buot-field-hire-start-date-sla'),
        numFmt: EXCEL_DATE_FORMAT,
        dataValidation: {
            type: 'date',
            formulae: [new Date(2020, 1, 1)],  // Arbitrary
            operator: 'greaterThanOrEqual',
            allowBlank: true,
            showInputMessage: true,
            promptTitle: t('enter-date'),
            prompt: t('enter-date-description', {
                dateFormat: DATE_FORMAT
            }),
            showErrorMessage: true,
            errorTitle: t('enter-valid-date'),
            error: t('invalid-or-too-late-date-entered')
        },
        additionalCellValueConversion: convertLocalTimeStampString,
    },
    {
        header: t('estimated-end-date') as string,
        columnLetter: "E",
        requiredLabelHeader: t('required') as string,
        numFmt: EXCEL_DATE_FORMAT,  // From Excel
        dataValidation: {
            type: 'date',
            formulae: [new Date()],  // We know for certain this has to be later than today,
            // even when the Excel sheet has been downloaded and passed around for a long time
            operator: 'greaterThan',
            allowBlank: true,
            showInputMessage: true,
            promptTitle: t('enter-date'),
            prompt: t('enter-estimated-end-date-description',  {
                dateFormat: DATE_FORMAT
            }),
            showErrorMessage: true,
            errorTitle: t('enter-valid-date'),
            error: t('invalid-estimated-end-date-description')
        },
        additionalCellValueConversion: convertLocalTimeStampString
    },
    {
        header: t('legal-first-name') as string,
        columnLetter: "F",
        requiredLabelHeader: t('required') as string,
    },
    {
        header: t('legal-middle-name') as string,
        columnLetter: "G",
        requiredLabelHeader: t('optional') as string,
    },
    {
        header: t('legal-last-name') as string,
        columnLetter: "H",
        requiredLabelHeader: t('required') as string,
    },
    {
        header: t('preferred-first-name') as string,
        columnLetter: "I",
        requiredLabelHeader: t('required-or-optional') as string,
        exampleDescription: t('buot-if-field-is-provided-then-required-otherwise-optional', {
            fieldName: t('preferred-last-name')
        }) as string,
    },
    {
        header: t('preferred-middle-name') as string,
        columnLetter: "J",
        requiredLabelHeader: t('optional') as string,
    },
    {
        header: t('preferred-last-name') as string,
        columnLetter: "K",
        requiredLabelHeader: t('required-or-optional') as string,
        exampleDescription: t('buot-if-field-is-provided-then-required-otherwise-optional', {
            fieldName: t('preferred-first-name')
        }) as string,
    },
    {
        header: t('email') as string,
        columnLetter: "L",
        requiredLabelHeader: t('required') as string,
        dataValidation: {
            type: 'custom',
            formulae: ['=AND(FIND("@",{cellAddress}),FIND(".",{cellAddress},FIND("@",{cellAddress})+2)-FIND("@",{cellAddress})>2)'],
            allowBlank: true,
            showInputMessage: true,
            promptTitle: t('enter-email'),
            prompt: t('required'),
            showErrorMessage: true,
            errorTitle: t('enter-email'),
            error: t('invalid-email'),
        }
    },
    {
        header: t('gender') as string,
        columnLetter: "M",
        requiredLabelHeader: t('required') as string,
        exampleDescription: t('buot-field-must-contain-one-of-following-options', {
            options: GENDER_OPTIONS.map(genderOption => genderOption.value).toString().replace(/,/g, ', ')
        }) as string,
        dataValidation: {
            type: 'list',
            formulae: FILLED_IN_VIA_A_REFERENCE_AT_RUNTIME,
            allowBlank: true,
            showErrorMessage: true,
            errorTitle: t('select-valid-option'),
            error: t('excel-dropdown-entry-error'),
        },
        dataValidationList: GENDER_OPTIONS.map(genderOption => genderOption.value),
        additionalCellValueConversion: (inputValue: string) => inputValue.toUpperCase(),
    },
    {
        header: t('date-of-birth') as string,
        columnLetter: "N",
        requiredLabelHeader: t('required') as string,
        numFmt: EXCEL_DATE_FORMAT,
        dataValidation: {
            type: 'date',
            formulae: [new Date(Date.now() - TEN_YEARS_IN_MILLISECONDS)],  // Arbitrarily set as 10 years+
            operator: 'lessThanOrEqual',
            allowBlank: true,
            showInputMessage: true,
            promptTitle: t('enter-valid-date'),
            prompt: t('enter-date-description', {
                dateFormat: DATE_FORMAT
            }),
            showErrorMessage: true,
            errorTitle: t('enter-valid-date'),
            error: t('invalid-or-too-early-date-of-birth-entered', {
                cutoffInYears: DOB_CUTOFF_IN_YEARS
            })
        },
        additionalCellValueConversion: convertLocalTimeStampString,
    },
    {
        header: t('national-id-country') as string,
        columnLetter: "O",
        requiredLabelHeader: t('required') as string,
        dataValidation: {
            type: 'list',
            formulae: FILLED_IN_VIA_A_REFERENCE_AT_RUNTIME,
            showErrorMessage: true,
            errorTitle: t('select-valid-option'),
            error: t('excel-dropdown-entry-error'),
        },
        dataValidationList: NATIONAL_ID_COUNTRY_OPTIONS.map(countryOption => countryOption.value),
        additionalCellValueConversion: (inputValue: string) => convertOptionValueToValue(inputValue, NATIONAL_ID_COUNTRY_OPTIONS),
    },
    {
        header: t('national-id-type') as string,
        columnLetter: "P",
        requiredLabelHeader: t('required') as string,
        dataValidation: {
            type: 'list',
            formulae: FILLED_IN_VIA_A_REFERENCE_AT_RUNTIME,
            showErrorMessage: true,
            errorTitle: t('select-valid-option'),
            error: t('excel-dropdown-entry-error'),
        },
        dataValidationList: NATIONAL_ID_TYPE_OPTIONS.map(idOption => idOption.label),
        additionalCellValueConversion: (inputLabel: string) => convertOptionLabelToValue(inputLabel, NATIONAL_ID_TYPE_OPTIONS),
    },
    {
        header: t('national-id') as string,
        columnLetter: "Q",
        requiredLabelHeader: t('required') as string,
    },
    {
        header: t('vendor-name') as string,
        columnLetter: "R",
        requiredLabelHeader: t('required') as string,
        dataValidation: {
            type: 'list',
            formulae: FILLED_IN_VIA_A_REFERENCE_AT_RUNTIME,
            showErrorMessage: true,
            errorTitle: t('select-valid-option'),
            error: t('excel-dropdown-entry-error'),
        },
        dataValidationList: Object.values(BackgroundCheckVendorName),
        additionalCellValueConversion: (inputValue: string) =>
            convertToListValue(inputValue, Object.values(BackgroundCheckVendorName)),
    },
    {
        header: t('vendor-ref-number') as string,
        columnLetter: "S",
        requiredLabelHeader: t('required') as string,
    },
    // {
    //     header: t('exception-id') as string,
    //     columnLetter: "T",
    //     requiredLabelHeader: t('optional') as string,
    // },
]);

/**
 * Class used to Generate the Bulk User Onboard template Excel file.
 */
export class TemplateGenerator {
    private readonly t: TFunction;
    private dataInputFields: DataInputField[];

    constructor(t: TFunction, scopeOptions: string[]) {
        this.t = t;
        this.dataInputFields = constructDataInputFields(this.t, scopeOptions);
    }

    /**
     * Generates the workbook.
     *
     * @param workbook The workbook to generate.
     */
    generateWorkbook(workbook: Workbook) {
        this.#translateInstructionsWorksheet(workbook);
        this.#translateExampleWorksheet(workbook);
        this.#generateDataWorksheet(workbook);
    }

    /**
     * Translates the 'Instructions' worksheet.
     *
     * @param workbook The workbook to translate.
     */
    #translateInstructionsWorksheet(workbook: Workbook) {
        const worksheet = workbook.worksheets[0];

        this.#translateName(worksheet, this.t('instructions'));
        this.#translateTitle(worksheet);

        worksheet.getCell('A2').value = this.t('bulk-onboard-template-instructions-step-1', {
            sheetName: this.t('example')
        }) as string;

        worksheet.getCell('A3').value = this.t('bulk-onboard-template-instructions-step-2', {
            sheetName: DATA_SHEET_NAME
        }) as string;

        worksheet.getCell('A5').value = this.t('bulk-onboard-template-instructions-step-3', {
            sheetName: DATA_SHEET_NAME,
            pageName: this.t('bulk-user-onboard')
        }) as string;
    }

    /**
     * Translates the 'Example' worksheet.
     *
     * @param workbook The workbook to translate.
     */
    #translateExampleWorksheet(workbook: Workbook) {
        const worksheet = workbook.worksheets[1];

        this.#translateName(worksheet, this.t('example'));
        this.#translateTitle(worksheet);
        this.#translateSectionHeaders(worksheet);
        this.#translateColumnHeaders(worksheet, 3);

        // Data requirements separator row
        worksheet.getCell('A8').value = this.t('buot-data-requirements-for-each-field-are-listed-below') as string;

        // Required, Optional, or Required/Optional row
        const requiredOrOptionalRow = 9
        this.dataInputFields.forEach(field => {
            worksheet.getCell(field.columnLetter + requiredOrOptionalRow).value = field.requiredLabelHeader;
        });


        const exampleDescriptionRow = 10;
        this.dataInputFields.forEach(field => {
            worksheet.getCell(field.columnLetter + exampleDescriptionRow).value = field.exampleDescription;
        });
    }

    /**
     * Generates the 'Data' worksheet.
     *
     * @param workbook The workbook to translate.
     */
    #generateDataWorksheet(workbook: Workbook) {
        const refWorksheet = workbook.getWorksheet('_DoNotDelete');
        this.#generateReferenceLists(refWorksheet);


        const dataWorksheet = workbook.worksheets[2];
        // Data sheet name is not translated on purpose because it makes parsing difficult
        // For more info, see discussion here: https://code.amazon.com/reviews/CR-93211047/revisions/1#/comments
        this.#translateTitle(dataWorksheet);
        this.#translateSectionHeaders(dataWorksheet);
        this.#translateColumnHeaders(dataWorksheet, COLUMN_NAME_EXCEL_ROW);
        this.#setupDataFields(dataWorksheet);
    }

    /**
     * Translates the worksheet's name.
     *
     * @param worksheet The worksheet to translate.
     * @param name The translated name for the worksheet.
     */
    #translateName(worksheet: Worksheet, name: string) {
        worksheet.name = name;
    }

    /**
     * Sets ups the hidden sheet used in the data sheet.
     * @param worksheet The bulk import workbook's reference worksheet.
     * @private
     */
    #generateReferenceLists(worksheet: Worksheet) {
        // Set the import version
        worksheet.getCell("A1").value = BULK_ONBOARD_IMPORT_VERSION;
        worksheet.getCell("A1").name = "version";

        // Set up input lists for each column
        const startRow = 3;
        let finalRow = 0;
        this.dataInputFields.forEach(inputField => {
            if (inputField.dataValidationList != null && inputField.dataValidation != null) {
                worksheet.getCell(inputField.columnLetter + startRow).value = inputField.header;
                inputField.dataValidationList.forEach((value, idx) => {
                    // Add 1 to adjust for the 0-based loops index in javascript and the 1-based index rows in Excel
                    const currentRow = startRow + 1 + idx;

                    // Add the value from the dataValidationList
                    worksheet.getCell(inputField.columnLetter + currentRow).value = value;
                    finalRow = currentRow;
                });

                inputField.dataValidation.formulae = [`=_DoNotDelete!$${inputField.columnLetter}$${startRow + 1}:$${inputField.columnLetter}$${finalRow}`]
            }
        });

        // Hide and protect the worksheet
        worksheet.state = 'hidden';
        worksheet.protect(DO_NOT_DELETE_EXCEL_SHEET_PROTECTION_STRING, {});
    }

    /**
     * Translates the worksheet's title.
     *
     * @param worksheet The worksheet to translate.
     */
    #translateTitle(worksheet: Worksheet) {
        worksheet.getCell('A1').value = this.t('bulk-user-onboard-template') as string;
    }

    /**
     * Translates the worksheet's section headers.
     *
     * @param worksheet The worksheet to translate.
     */
    #translateSectionHeaders(worksheet: Worksheet) {
        worksheet.getCell('A2').value = this.t('general') as string;
        worksheet.getCell('F2').value = this.t('personal-info') as string;
        worksheet.getCell('O2').value = this.t('identification') as string;
        worksheet.getCell('R2').value = this.t('background-check') as string;
    }

    /**
     * Translates the worksheet's column names.
     *
     * @param worksheet The worksheet to translate.
     * @param row The row number for the header.
     */
    #translateColumnHeaders(worksheet: Worksheet, row: number) {
        this.dataInputFields.forEach(field => {
            worksheet.getCell(field.columnLetter + row).value = field.header;
        });
    }

    #setupDataFields(worksheet: Worksheet) {
        for(let i = DATA_START_EXCEL_ROW; i <= DATA_END_EXCEL_ROW; i++) {
            logger.info(`Setting up data fields for row: ${i}`)
            this.dataInputFields.forEach(field => {
                let cell = worksheet.getCell(field.columnLetter + i);

                if (field.dataValidation !== undefined) {
                    // Copy the validation object, so we don't write the first row's information
                    // and use it for every other row
                    let validation = { ...field.dataValidation };
                    validation.formulae = [ ...field.dataValidation.formulae];
                    if (field.dataValidation.type === 'custom' && field.dataValidation.formulae[0].includes('{cellAddress}')) {
                        validation.formulae[0] = field.dataValidation.formulae[0].replaceAll('{cellAddress}', cell.address);
                    }
                    cell.dataValidation = validation;
                }

                if (field.numFmt !== undefined) {
                    cell.numFmt = field.numFmt;
                }

            });
        }

        // Add a black row where the data import stops
        this.dataInputFields.forEach(field => {
            worksheet.getCell(field.columnLetter + (DATA_END_EXCEL_ROW + 1)).fill = {
                type: 'pattern',
                pattern: 'solid',
                bgColor: {argb: BLACK_COLOR_HEXADECIMAL},
            };
        })
    }
}
